@tailwind base;
@tailwind components;
@tailwind utilities;

/* 定制滚动条轨道 */
.textarea {
    /* overflow-x: auto; 允许水平滚动 */
    white-space: pre; /* 防止自动换行 */
    /* 定制滚动条样式 */
    --scrollbarBG: #333;
    --thumbBG: #555;
  }
  
.textarea::-webkit-scrollbar {
    height: 8px;
    background: var(--scrollbarBG);
  }
  
.textarea::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 4px;
  }

.message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    animation: fadeIn 0.5s;
}

.success {
    background-color: #4CAF50; /* Green */
}

.error {
    background-color: #f44336; /* Red */
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fly {
    0% { transform: translateY(0); opacity: 0; }
    50% { opacity: 1; }
    100% { transform: translateY(100px); opacity: 0; }
  }
  
  .animate-fly {
    animation: fly 1s forwards;
  }
